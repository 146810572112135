import { either, these } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';

import { Action, InfoWithRequired, State } from './state';
import { unreachable } from './unreachable';
import {
  validateCode,
  validateFamilyInfo,
  validateInvitationResponse,
  validatePersonInfo,
  ValidationEither,
  ValidationError,
  validationSequenceS,
} from './validate';

type Reducer<TAction extends Action['type'] = Action['type']> = (
  state: State,
  action: Action<TAction>,
  previousErrors: ValidationError[],
) => these.These<ValidationError[], State>;

const requireStateRoute = <TRoute extends State['_route']>(
  state: State,
  route: TRoute,
): ValidationEither<State<TRoute>> => {
  if (state._route === route) {
    return either.right(state as State & { _route: TRoute });
  }
  return either.left(['wrongRoute']);
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
const reducers: {
  [TAction in Action['type']]: Reducer<TAction>;
} = {
  updateCode: (_state, action) => {
    return either.right({
      _route: 'EnteringCode',
      info: {
        code: action.code,
      },
    });
  },
  invitationResponse: (state, action) => {
    return pipe(
      validationSequenceS({
        code: validateCode(state.info.code),
        invitationResponse: validateInvitationResponse(
          action.invitationResponse,
        ),
      }),
      either.map(({ code, invitationResponse }) => {
        switch (invitationResponse._tag) {
          case 'done':
            return {
              _route: 'DoneOverview' as const,
              info: invitationResponse.previousAnswer,
            };
          case 'found':
            return {
              _route: action.skipConfirmation
                ? ('FamilyInfo' as const)
                : ('Welcome' as const),
              info: {
                // Not spreading state.info to allow resetting form
                // ...state.info,
                invitation: invitationResponse.invitation,
                code,
              },
            };
          default:
            return unreachable(invitationResponse);
        }
      }),
    );
  },
  checkIn: (state) => {
    return pipe(
      requireStateRoute(state, 'Welcome'),
      either.map((welcomeState) => ({
        ...welcomeState,
        _route: 'FamilyInfo' as const,
      })),
    );
  },
  updateFamilyInfo: (state, action) => {
    return pipe(
      requireStateRoute(state, 'FamilyInfo'),
      either.map((familyInfoState) => ({
        ...familyInfoState,
        info: {
          ...familyInfoState.info,
          familyInfo: action.familyInfo,
        },
      })),
    );
  },
  confirmFamilyInfo: (state) => {
    return pipe(
      requireStateRoute(state, 'FamilyInfo'),
      either.chain((familyInfoState) =>
        validateFamilyInfo(familyInfoState.info),
      ),
      either.map((info) =>
        info.familyInfo.aantalPersonen === 0
          ? {
              _route: 'Confirm' as const,
              info: {
                ...info,
                personInfo: [],
              },
            }
          : {
              _route: 'PersonInfo' as const,
              info: {
                ...info,
                personInfo:
                  info.invitation.fixedPersonen.length <=
                  info.familyInfo.aantalPersonen
                    ? info.invitation.fixedPersonen.map((p, index) => ({
                        ...info.personInfo?.[index],
                        voornaam: p.voornaam,
                        familienaam: p.familienaam,
                      }))
                    : info.personInfo,
              },
            },
      ),
    );
  },
  updatePersonInfo: (state, action, previousErrors) => {
    return pipe(
      requireStateRoute(state, 'PersonInfo'),
      either.map((personInfoState) => {
        const newPersonInfo = [...(personInfoState.info.personInfo ?? [])];
        newPersonInfo[action.personIndex] = action.personInfo;

        return {
          ...personInfoState,
          info: {
            ...personInfoState.info,
            personInfo: newPersonInfo,
          },
        };
      }),
      (result) => {
        if (either.isLeft(result)) {
          return result;
        }
        return these.both(previousErrors, result.right);
      },
    );
  },
  checkPersonInfo: (state, _, previousErrors) => {
    return pipe(
      requireStateRoute(state, 'PersonInfo'),
      either.chain(
        (
          personInfoState,
        ): either.Either<
          ValidationError[],
          InfoWithRequired<'familyInfo' | 'code' | 'invitation'>
        > => {
          if (previousErrors.length === 0) {
            return either.right(personInfoState.info);
          }
          return validatePersonInfo(personInfoState.info);
        },
      ),
      either.map((info) => ({
        _route: 'PersonInfo',
        info,
      })),
    );
  },
  confirmPersonInfo: (state) => {
    return pipe(
      requireStateRoute(state, 'PersonInfo'),
      either.chain((personInfoState) =>
        validatePersonInfo(personInfoState.info),
      ),
      either.map((info) => ({
        _route: 'Confirm' as const,
        info,
      })),
    );
  },
  confirmAll: (state) => {
    return pipe(
      requireStateRoute(state, 'Confirm'),
      either.map((confirmState) => ({
        ...confirmState,
        _route: 'End' as const,
      })),
    );
  },
  back: (state) => {
    switch (state._route) {
      case 'EnteringCode':
        return either.left(['wrongRoute']);
      case 'Welcome':
        return either.right({ ...state, _route: 'EnteringCode' });
      case 'FamilyInfo':
        return either.right({ ...state, _route: 'EnteringCode' });
      case 'PersonInfo':
        return either.right({ ...state, _route: 'FamilyInfo' });
      case 'Confirm':
        if (state.info.familyInfo.aantalPersonen === 0) {
          return either.right({ ...state, _route: 'FamilyInfo' });
        }
        return either.right({ ...state, _route: 'PersonInfo' });
      case 'End':
        return either.left(['wrongRoute']);
      case 'DoneOverview':
        return either.right({ _route: 'EnteringCode', info: {} });
      default:
        return unreachable(state);
    }
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const reducer: Reducer = (state, action, previousErrors) => {
  // @ts-expect-error complexity
  const reducerForAction: Reducer | null = reducers[action.type];
  if (reducerForAction == null) {
    return either.right(state);
  }
  return reducerForAction(state, action, previousErrors);
};

export type StateWithErrors = {
  errors: ValidationError[];
  state: State;
};
const initialState: StateWithErrors = {
  errors: [],
  state: { _route: 'EnteringCode', info: {} },
};
type ReduxReducer = (
  state: StateWithErrors | undefined,
  action: Action,
) => StateWithErrors;
export const reduxReducer: ReduxReducer = (state = initialState, action) => {
  const result = reducer(state.state, action, state.errors);
  switch (result._tag) {
    case 'Left':
      return {
        errors: result.left,
        state: state.state,
      };
    case 'Right':
      return {
        errors: [],
        state: result.right,
      };
    case 'Both':
      return {
        errors: result.left,
        state: result.right,
      };
    default:
      return unreachable(result);
  }
};
