import { configureStore, ThunkAction } from '@reduxjs/toolkit';

import { reduxReducer, StateWithErrors } from './reducer';

const ITEM_NAME = 'savedState';
const saveStateToStarge = (state: StateWithErrors) => {
  if (state.state._route === 'End') {
    localStorage.removeItem(ITEM_NAME);
  } else {
    localStorage.setItem(ITEM_NAME, JSON.stringify(state));
  }
};
const loadStateFromStorage = () => {
  try {
    const stateFromStorage = localStorage.getItem(ITEM_NAME);
    if (stateFromStorage == null) {
      return undefined;
    }
    return JSON.parse(stateFromStorage);
  } catch (error) {
    return undefined;
  }
};
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(({ getState }) => (next) => (action) => {
      const returnValue = next(action);
      saveStateToStarge(getState());
      return returnValue;
    }),
  preloadedState: loadStateFromStorage(),
  reducer: reduxReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppAction = Parameters<AppDispatch>[0];
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AppAction
>;

export const selectRouterState = (state: RootState) => state.state;
export const selectErrors = (state: RootState) => state.errors;
