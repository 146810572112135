import { RouteProps } from '../app/state';
import us from '../img/sara-en-jens.jpg';
import { Step } from '../lib/Step';

export const EndRoute = ({ info, route }: RouteProps<'familyInfo'>) => {
  return (
    <Step backEnabled={false} route={route} title={'Bedankt!'}>
      <div className="m-4 flex flex-col flex-grow items-center justify-center">
        <img className="my-4" src={us} width={600} />
        <p>
          Je rsvp is successvol geregistreerd.{' '}
          {info.familyInfo.aantalPersonen > 0
            ? 'We kijken uit naar je komst.'
            : ''}
        </p>
      </div>
    </Step>
  );
};
