import { useEffect, useMemo } from 'react';

import { useAppSelector } from './app/hooks';
import { selectRouterState } from './app/store';
import { unreachable } from './app/unreachable';
import { Step } from './lib/Step';
import { ConfirmRoute } from './routes/ConfirmRoute';
import { DoneOverviewRoute } from './routes/DoneOverviewRoute';
import { EndRoute } from './routes/EndRoute';
import { EnteringCode, useGetInvitation } from './routes/EnteringCode';
import { FamilyInfoRoute } from './routes/FamilyInfoRoute';
import { PersonInfoRoute } from './routes/PersonInfoRoute';
import { Welcome } from './routes/Welcome';

const RSVP_REGEX = /\/rsvp\/([A-Z]{4,})/;

export const Router = () => {
  const state = useAppSelector(selectRouterState);

  const [loading, getInvitation] = useGetInvitation({
    skipConfirmation: false,
  });
  const codeFromUrl = useMemo(() => {
    const { pathname } = new URL(window.location.href);
    const match = pathname.match(RSVP_REGEX);
    if (!match) {
      return null;
    }
    return match[1];
  }, []);
  useEffect(() => {
    if (codeFromUrl !== null && codeFromUrl !== '') {
      getInvitation(codeFromUrl);
    }
  }, [codeFromUrl, getInvitation]);
  if (loading) {
    return (
      <Step backEnabled={false} route={'EnteringCode'} title={'RSVP'}>
        <div className="flex flex-col flex-grow md:flex-row items-center justify-center">
          We zijn jouw uitnodiging aan het laden...
        </div>
      </Step>
    );
  }

  switch (state._route) {
    case 'EnteringCode':
      return <EnteringCode info={state.info} route={state._route} />;
    case 'Welcome':
      return <Welcome info={state.info} route={state._route} />;
    case 'FamilyInfo':
      return <FamilyInfoRoute info={state.info} route={state._route} />;
    case 'PersonInfo':
      return <PersonInfoRoute info={state.info} route={state._route} />;
    case 'Confirm':
      return <ConfirmRoute info={state.info} route={state._route} />;
    case 'End':
      return <EndRoute info={state.info} route={state._route} />;
    case 'DoneOverview':
      return <DoneOverviewRoute info={state.info} route={state._route} />;
    default:
      return unreachable(state);
  }
};
