/* eslint-disable no-nested-ternary */
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RouteProps } from '../app/state';
import { selectErrors } from '../app/store';
import { REQUIRE_ADDRESS } from '../app/validate';
import { Button, Field, field, Select } from '../lib/Form';
import { Step } from '../lib/Step';

export const FamilyInfoRoute = ({ info, route }: RouteProps<'invitation'>) => {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);

  const familyField = field(info.familyInfo, (newFamilyInfo) => {
    dispatch({
      familyInfo: newFamilyInfo,
      type: 'updateFamilyInfo',
    });
  });
  const adresField = field(info.familyInfo?.adres, (newAdresInfo) => {
    dispatch({
      familyInfo: {
        ...info.familyInfo,
        adres: newAdresInfo,
      },
      type: 'updateFamilyInfo',
    });
  });

  const AantalPersonen = () => {
    return (
      <Select
        errorMessages={{
          'family/aantalPersonen/required': 'Het aantal personen is verplicht',
        }}
        errors={errors}
        {...familyField('aantalPersonen', (value) =>
          value === '' ? undefined : parseInt(value, 10),
        )}
        title={'Aantal Personen'}
      >
        <option value="" />
        <option value="0">
          {info.invitation.maxAantalPersonen === 1 ? 'Ik kan ' : 'Wij kunnen '}
          helaas niet aanwezig zijn
        </option>
        <option value="1">
          {info.invitation.maxAantalPersonen === 1
            ? 'Ik zal aanwezig zijn'
            : 'Wij komen met 1 persoon'}
        </option>
        {new Array(info.invitation.maxAantalPersonen - 1)
          .fill(0)
          .map((_, i) => {
            return (
              <option value={`${i + 2}`}>Wij komen met {i + 2} personen</option>
            );
          })}
      </Select>
    );
  };

  return (
    <Step
      route={route}
      title={
        REQUIRE_ADDRESS
          ? 'Contactgegevens'
          : info.invitation.maxAantalPersonen > 1
          ? 'Zijn jullie aanwezig?'
          : 'Ben je aanwezig?'
      }
    >
      <div className="flex flex-grow">
        <form
          className="flex flex-col md:p-8 pt-4 flex-grow"
          onSubmit={(event) => {
            event.preventDefault();
            dispatch({
              type: 'confirmFamilyInfo',
            });
          }}
        >
          <AantalPersonen />
          {REQUIRE_ADDRESS && (info.familyInfo?.aantalPersonen ?? 0) > 0 && (
            <>
              <Field
                {...adresField('straatnaam')}
                errorMessages={{
                  'family/adres/straatnaam/required': 'Straatnaam is verplicht',
                }}
                errors={errors}
                title={'Straatnaam'}
              />
              <Field
                {...adresField('huisnummer')}
                errorMessages={{
                  'family/adres/huisnummer/required': 'Huisnummer is verplicht',
                }}
                errors={errors}
                title={'Huisnummer (& busnummer)'}
              />
              <Field
                {...adresField('postcode')}
                errorMessages={{
                  'family/adres/postcode/required': 'Postcode is verplicht',
                }}
                errors={errors}
                title={'Postcode'}
              />
              <Field
                {...adresField('gemeente')}
                errorMessages={{
                  'family/adres/gemeente/required': 'Gemeente is verplicht',
                }}
                errors={errors}
                title={'Gemeente'}
              />
            </>
          )}
          <Button type="submit">Volgende</Button>
        </form>
      </div>
    </Step>
  );
};
