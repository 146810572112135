import { useAppDispatch } from '../app/hooks';
import { RouteProps } from '../app/state';
import { Button } from '../lib/Form';
import { RsvpFlippableCard } from '../lib/RsvpFlippableCard';

export const Welcome = ({
  info: { invitation },
}: RouteProps<'code' | 'invitation'>) => {
  const dispatch = useAppDispatch();

  return (
    <RsvpFlippableCard>
      <div className="flex items-center justify-center flex-col flex-grow h-full">
        <div className="flex flex-col flex-grow justify-center">
          <h1 className="mb-4">Welkom {invitation.name}!</h1>
          <p className="max-w-sm">
            {invitation.maxAantalPersonen > 1
              ? 'We zijn heel benieuwd om te weten of jullie op ons huwelijk aanwezig kunnen zijn.'
              : 'We zijn heel benieuwd om te weten of je op ons huwelijk aanwezig kan zijn.'}
          </p>
          <p className="pt-4 font-schrift text-4xl">Sara & Jens</p>
        </div>
        <form
          className="flex flex-col md:p-8 pt-4 w-full self-end mt-auto"
          onSubmit={() => dispatch({ type: 'checkIn' })}
        >
          <Button type="submit">Start</Button>
        </form>
      </div>
    </RsvpFlippableCard>
  );
};
