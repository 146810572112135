import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

import { useAppDispatch } from '../app/hooks';
import { State } from '../app/state';

import { Card } from './RsvpFlippableCard';

type RouteName = State['_route'];

type StepProps = {
  backEnabled?: boolean;
  children?: ReactNode;
  route: RouteName;
  subtitle?: string;
  title: string;
};

export const Step = ({ backEnabled = true, children, title }: StepProps) => {
  const dispatch = useAppDispatch();

  return (
    <Card>
      <div className="flex flex-col flex-grow">
        <header className={`flex items-center justify-center`}>
          <div className="flex self-start">
            {backEnabled && (
              <FontAwesomeIcon
                className="rounded py-2 px-0"
                icon={faArrowLeft}
                onClick={() => {
                  dispatch({ type: 'back' });
                }}
                size={'1x'}
              />
            )}
          </div>
          <div className="flex flex-grow flex-col justify-center items-center">
            <h1 className="font-capital text-xl text-center">{title}</h1>
          </div>
          <div className="flex px-2 items-center justify-end"></div>
        </header>
        {children}
      </div>
    </Card>
  );
};
