import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

import orp from '../img/orp.jpg';

export const Card = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-screen">
      <div className="lg:w-2/3 w-full min-h-full flex flex-col flex-grow bg-white border-2 border-grey p-4 md:p-6 my-5vh">
        {children}
      </div>
    </div>
  );
};
export const RsvpFlippableCard = ({ children }: { children: ReactNode }) => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFlipped(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center h-screen w-screen"
      onClick={() => setFlipped(true)}
    >
      <div
        className={classNames([
          'flip-card lg:w-2/3 w-full h-full my-5vh flex',
          flipped && 'flip-card-flipped',
        ])}
      >
        <div className="flip-card-inner">
          <div className="flip-card-front bg-white">
            <div className="border-2 border-grey p-12 w-full h-full flex flex-col justify-center items-center">
              <img
                alt="Avatar"
                className="max-h-80p max-w-80p object-contain"
                src={orp}
              />
              <h1 className="font-schrift md:text-7xl text-3xl">Sara & Jens</h1>
              <h2 className="font-capital md:text-3xl text-l">8 Juni 2024</h2>
            </div>
          </div>
          <div className="flip-card-back bg-white">
            <div className="border-2 border-grey p-4 md:p-6 w-full h-full">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
