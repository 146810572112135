import classNames from 'classnames';
import { either } from 'fp-ts';
import { ReactNode, useState } from 'react';

import { apiClient } from '../apiClient';
import { useAppDispatch } from '../app/hooks';
import { EtenInfo, InfoWithRequired, RouteProps } from '../app/state';
import { Button, ConfirmField } from '../lib/Form';
import { Step } from '../lib/Step';

export const Invited = ({
  children,
  invited,
}: {
  children: ReactNode;
  invited: boolean;
}) => {
  if (!invited) {
    return null;
  }

  return <>{children}</>;
};
const Aanwezig = ({
  aanwezig,
  children,
}: {
  aanwezig: boolean | undefined;
  children: React.ReactNode;
}) => {
  return aanwezig ? <li>Aanwezig {children}</li> : <li>Afwezig {children}</li>;
};

const vegetarischText = ({
  vegetarisch,
  vegetarischOpmerking,
}: Partial<EtenInfo>) => {
  switch (vegetarisch) {
    case 'eet-vlees':
      return 'Ik eet vlees';
    case 'vegetarisch':
      return `Ik eet vegetarisch (${vegetarischOpmerking})`;
    case 'pescotarier':
      return `Ik eet pescotarisch (${vegetarischOpmerking})`;
    case 'vegan':
      return `Ik eet vegan (${vegetarischOpmerking})`;
    default:
      return `Ik eet ${vegetarisch} (${vegetarischOpmerking})`;
  }
};

export const InfoToConfirm = ({
  className,
  info,
}: {
  className?: string;
  info: InfoWithRequired<'code' | 'invitation' | 'familyInfo' | 'personInfo'>;
}) => {
  const {
    invitedToCeremony,
    invitedToDansfeest,
    invitedToDinner,
    invitedToReceptie,
  } = info.invitation;
  const { gemeente, huisnummer, postcode, straatnaam } =
    info.familyInfo.adres ?? {};

  return (
    <div className={classNames(['mb-4', className])}>
      {info.familyInfo.adres && (
        <ConfirmField title="Adres">
          {straatnaam} {huisnummer}
          <br />
          {postcode} {gemeente}
        </ConfirmField>
      )}
      {info.familyInfo.aantalPersonen === 0 && (
        <ConfirmField title="Aantal personen">
          {info.invitation.maxAantalPersonen === 1 ? 'Ik kan ' : 'Wij kunnen '}
          helaas niet aanwezig zijn
        </ConfirmField>
      )}
      {info.personInfo.map((person) => {
        const {
          aanwezigCeremonie,
          aanwezigDans,
          aanwezigEten,
          aanwezigReceptie,
          dans,
          emailadres,
          eten,
          familienaam,
          gsmNummer,
          voornaam,
        } = person;
        return (
          <ConfirmField title={`${voornaam} ${familienaam}`}>
            {emailadres}
            <br />
            {gsmNummer && (
              <>
                {gsmNummer}
                <br />
              </>
            )}
            <ul className="list-none list-outside">
              <Invited invited={invitedToCeremony}>
                <Aanwezig aanwezig={aanwezigCeremonie}>
                  op de huwelijksceremonie
                </Aanwezig>
              </Invited>
              <Invited invited={invitedToReceptie}>
                <Aanwezig aanwezig={aanwezigReceptie}>op de receptie</Aanwezig>
              </Invited>
              <Invited invited={invitedToDinner}>
                <Aanwezig aanwezig={aanwezigEten}>
                  voor het avondeten
                  {eten != null && (
                    <ul className="list-inside list-none ml-6">
                      <li>
                        {eten.allergie
                          ? `ik heb een voedselallergie (${eten.allergieOpmerking})`
                          : 'ik heb geen voedselallergie'}
                      </li>
                      <li>{eten.vegetarisch && vegetarischText(eten)}</li>
                    </ul>
                  )}
                </Aanwezig>
              </Invited>
              <Invited invited={invitedToDansfeest}>
                <Aanwezig aanwezig={aanwezigDans}>
                  op het dessert & dansfeest
                </Aanwezig>
                {dans != null && dans.verzoeknummer !== '' && (
                  <ul className="list-inside list-none ml-6">
                    <li>
                      Verzoeknummer:{' '}
                      <span className="italic">{dans?.verzoeknummer}</span>
                    </li>
                  </ul>
                )}
              </Invited>
            </ul>
          </ConfirmField>
        );
      })}
    </div>
  );
};
export const ConfirmRoute = ({
  info,
  route,
}: RouteProps<'code' | 'invitation' | 'familyInfo' | 'personInfo'>) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  return (
    <Step route={route} title={'Overzicht antwoorden'}>
      <form
        className="flex flex-col md:p-8 pt-4 flex-grow justify-center"
        onSubmit={async (event) => {
          event.preventDefault();

          setLoading(true);
          try {
            const response = await apiClient.submitResponse(info);
            if (either.isLeft(response)) {
              // eslint-disable-next-line no-alert
              alert(
                'Het opslagen is momenteel niet gelukt. Probeer het (later) opnieuw. Je antwoorden blijven normaal opgeslagen op je pc/gsm',
              );
            } else {
              dispatch({
                type: 'confirmAll',
              });
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        <InfoToConfirm className="flex flex-grow flex-col" info={info} />
        <span className="font-bold mb-4 text-center">
          Uw gegevens zullen worden doorgestuurd, gelieve het bovenstaande te
          dubbelchecken alvorens te bevestigen
        </span>
        <Button disabled={isLoading} type="submit">
          {isLoading ? 'Aan het versturen...' : 'Bevestig'}
        </Button>
      </form>
    </Step>
  );
};
