/* eslint-disable sonarjs/cognitive-complexity */
import { apply, array, either, nonEmptyArray, option } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';

import type { InvitationResponse } from '../apiClient';

import {
  DeepPartial,
  EtenInfo,
  FamilyInfo,
  InfoWithRequired,
  PersonInfo,
} from './state';
import { unreachable } from './unreachable';

const isEmptyString = (x: string | null | undefined): x is null | undefined => {
  return x == null || x === '';
};

const validation = either.getApplicativeValidation(
  array.getSemigroup<ValidationError>(),
);
export type ValidationEither<T> = either.Either<ValidationError[], T>;
export const validationSequenceS = apply.sequenceS(validation);
const validationSequenceT = apply.sequenceT(validation);
const validationSequenceA = <T extends ValidationEither<unknown>>(
  arr: nonEmptyArray.NonEmptyArray<T>,
) => validationSequenceT(nonEmptyArray.head(arr), ...nonEmptyArray.tail(arr));

type CodeError = 'code/notValid' | 'invitation/notFound';
export const validateCode = (
  codeInput: string | undefined,
): either.Either<ValidationError[], string> => {
  const code = codeInput?.toUpperCase();

  if (isEmptyString(code)) {
    return either.left(['code/notValid']);
  }

  return either.right(code);
};

export const validateInvitationResponse = (
  invitationResponse: InvitationResponse,
): ValidationEither<InvitationResponse & { _tag: 'done' | 'found' }> => {
  switch (invitationResponse._tag) {
    case 'done':
    case 'found':
      return either.right(invitationResponse);
    case 'notFound':
      return either.left(['invitation/notFound']);
    default:
      return unreachable(invitationResponse);
  }
};
const required =
  <TPrefix extends string, FormData>(
    prefix: TPrefix,
    formData: Partial<FormData> | undefined,
  ) =>
  <TFieldName extends keyof FormData & string>(fieldName: TFieldName) => {
    const value = formData?.[fieldName];
    if (value == null) {
      return either.left([`${prefix}/${fieldName}/required` as const]);
    }
    if (typeof value === 'string' && value === '') {
      return either.left([`${prefix}/${fieldName}/required` as const]);
    }
    return either.right(value as FormData[TFieldName]);
  };

type FamilyInfoError =
  | 'family/aantalPersonen/required'
  | 'family/adres/gemeente/required'
  | 'family/adres/huisnummer/required'
  | 'family/adres/postcode/required'
  | 'family/adres/straatnaam/required';

export const REQUIRE_ADDRESS = false;
export const validateFamilyInfo = (
  info: InfoWithRequired<'code' | 'invitation'>,
): ValidationEither<InfoWithRequired<'code' | 'invitation' | 'familyInfo'>> => {
  const familyRequired = required('family', info.familyInfo);
  const adresRequired = required('family/adres', info.familyInfo?.adres);

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  return pipe(
    familyRequired('aantalPersonen'),
    either.chain(
      (aantalPersonen): ValidationEither<FamilyInfo> =>
        pipe(
          REQUIRE_ADDRESS && aantalPersonen > 0
            ? validationSequenceS({
                straatnaam: adresRequired('straatnaam'),
                huisnummer: adresRequired('huisnummer'),
                postcode: adresRequired('postcode'),
                gemeente: adresRequired('gemeente'),
              })
            : either.right(undefined),
          either.map((adres: FamilyInfo['adres']) => ({
            adres,
            aantalPersonen,
          })),
        ),
    ),
    either.map((familyInfo) => ({ ...info, familyInfo })),
  );
  /* eslint-enable sort-keys-fix/sort-keys-fix */
};

// should be number but doesn't work well
// type PersonIndex = number;
export type PersonIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type PersonError =
  | `person/people/required`
  | `person/${PersonIndex}/voornaam/required`
  | `person/${PersonIndex}/emailadres/required`
  | `person/${PersonIndex}/familienaam/required`
  | `person/${PersonIndex}/gsmNummer/required`
  | `person/${PersonIndex}/aanwezigCeremonie/required`
  | `person/${PersonIndex}/aanwezigReceptie/required`
  | `person/${PersonIndex}/aanwezigDans/required`
  | `person/${PersonIndex}/dans/verzoeknummer/required`
  | `person/${PersonIndex}/aanwezigEten/required`
  | `person/${PersonIndex}/eten/allergie/required`
  | `person/${PersonIndex}/eten/allergieOpmerking/required`
  | `person/${PersonIndex}/eten/vegetarisch/required`
  | `person/${PersonIndex}/eten/vegetarischOpmerking/required`;

export const validatePersonInfo = (
  info: InfoWithRequired<'code' | 'invitation' | 'familyInfo'>,
): ValidationEither<
  InfoWithRequired<'code' | 'invitation' | 'familyInfo' | 'personInfo'>
> => {
  const validatePerson = (
    index: number,
    personInfo: DeepPartial<PersonInfo> | undefined,
  ) => {
    const personIndex = index as PersonIndex;
    const personRequired = required(
      `person/${personIndex}` as const,
      personInfo,
    );

    // const dansRequired = required(
    //   `person/${personIndex}/dans` as const,
    //   personInfo?.dans,
    // );
    const etenRequired = required(
      `person/${personIndex}/eten` as const,
      personInfo?.eten,
    );

    const {
      invitedToCeremony,
      invitedToDansfeest,
      invitedToDinner,
      invitedToReceptie,
    } = info.invitation;

    const requiredIfOtherFieldTrue = <
      TRequiredFieldName extends keyof EtenInfo,
      TBooleanFieldName extends keyof EtenInfo,
    >(
      requiredFieldName: TRequiredFieldName,
      booleanFieldName: TBooleanFieldName,
    ) =>
      personInfo?.eten?.[booleanFieldName]
        ? etenRequired(requiredFieldName)
        : either.right(undefined);

    const aanwezigEtenValidated = invitedToDinner
      ? personRequired('aanwezigEten')
      : either.right(false);
    const aanwezigReceptieValidated = invitedToReceptie
      ? personRequired('aanwezigReceptie')
      : either.right(false);
    const aanwezigDansValidated = invitedToDansfeest
      ? personRequired('aanwezigDans')
      : either.right(false);

    /* eslint-disable sort-keys-fix/sort-keys-fix */
    return pipe(
      validationSequenceT(
        validationSequenceS({
          voornaam: personRequired('voornaam'),
          familienaam: personRequired('familienaam'),
          // gsmNummer: personRequired('gsmNummer'),
          gsmNummer: either.right(personInfo?.gsmNummer ?? ''),
          emailadres: personRequired('emailadres'),
          aanwezigCeremonie: invitedToCeremony
            ? personRequired('aanwezigCeremonie')
            : either.right(false),
          fixedPersonIndex: either.right(personInfo?.fixedPersonIndex ?? null),
        }),
        pipe(
          validationSequenceT(aanwezigEtenValidated, aanwezigReceptieValidated),
          either.chain(([aanwezigEten, aanwezigReceptie]) => {
            const eats =
              (invitedToDinner && aanwezigEten) ||
              (invitedToReceptie && aanwezigReceptie);
            return validationSequenceS({
              aanwezigEten: either.right(aanwezigEten),
              aanwezigReceptie: either.right(aanwezigReceptie),
              eten: eats
                ? validationSequenceS({
                    allergie: etenRequired('allergie'),
                    allergieOpmerking: requiredIfOtherFieldTrue(
                      'allergieOpmerking',
                      'allergie',
                    ),
                    vegetarisch: etenRequired('vegetarisch'),
                    vegetarischOpmerking: either.right(
                      personInfo?.eten?.vegetarischOpmerking ?? '',
                    ),
                    // vegetarischOpmerking: requiredIfOtherFieldTrue(
                    //   'vegetarischOpmerking',
                    //   'vegetarisch',
                    // ),
                  })
                : either.right(undefined),
            });
          }),
        ),
        pipe(
          aanwezigDansValidated,
          either.chain((aanwezigDans) =>
            validationSequenceS({
              // Dans
              aanwezigDans: aanwezigDansValidated,
              dans:
                invitedToDansfeest && aanwezigDans
                  ? validationSequenceS({
                      verzoeknummer: either.right(
                        personInfo?.dans?.verzoeknummer ?? '',
                      ),
                    })
                  : either.right(undefined),
            }),
          ),
        ),
      ),
      either.map(
        ([general, eten, dans]): PersonInfo => ({
          ...general,
          ...eten,
          ...dans,
        }),
      ),
    );
    /* eslint-enable sort-keys-fix/sort-keys-fix */
  };

  const relevantPersonInfo = new Array(info.familyInfo.aantalPersonen)
    .fill(0)
    .map((_, i) => info.personInfo?.[i]);

  return pipe(
    relevantPersonInfo,
    nonEmptyArray.fromArray,
    option.map(nonEmptyArray.mapWithIndex(validatePerson)),
    option.getOrElse(() =>
      nonEmptyArray.of(
        either.left<ValidationError[], PersonInfo>(['person/people/required']),
      ),
    ),
    validationSequenceA,
    either.map((personInfo) => ({
      ...info,
      personInfo,
    })),
  );
};

type ReducerError = 'wrongRoute';
export type ValidationError =
  | CodeError
  | FamilyInfoError
  | PersonError
  | ReducerError;
