import { useCallback, useState } from 'react';

import { apiClient } from '../apiClient';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RouteProps } from '../app/state';
import { AppThunk, selectErrors } from '../app/store';
import { Button, Field, field } from '../lib/Form';
import { RsvpFlippableCard } from '../lib/RsvpFlippableCard';

const getInvitationThunk =
  ({
    codeCased,
    setIsLoading,
    skipConfirmation,
  }: {
    codeCased: string;
    setIsLoading: (loading: boolean) => void;
    skipConfirmation: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    const code = codeCased.toUpperCase();
    const currentState = getState().state;
    if (
      currentState.info.invitation?.code === code &&
      currentState._route !== 'EnteringCode'
    ) {
      return;
    }

    setIsLoading(true);
    dispatch({ code, type: 'updateCode' });

    try {
      const invitationResponse = await apiClient.getInvitation({ code });
      dispatch({
        invitationResponse,
        skipConfirmation,
        type: 'invitationResponse',
      });
    } catch (_error) {
      // eslint-disable-next-line no-alert
      alert(
        'We konden op dit moment je uitnodiging niet ophalen. Probeer eens opnieuw',
      );
    } finally {
      setIsLoading(false);
    }
  };

export const useGetInvitation = ({
  skipConfirmation,
}: {
  skipConfirmation: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const getInvitation = useCallback(
    async (codeCased: string | undefined) => {
      if (codeCased == null) {
        return;
      }

      dispatch(
        getInvitationThunk({
          codeCased,
          setIsLoading: setLoading,
          skipConfirmation,
        }),
      );
    },
    [dispatch, skipConfirmation],
  );
  return [loading, getInvitation] as const;
};

type CodeForm = {
  code: string;
};
export const EnteringCode = ({ info }: RouteProps) => {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);

  const [loading, getInvitation] = useGetInvitation({ skipConfirmation: true });
  const codeField = field<CodeForm>({ code: info.code }, ({ code }) => {
    if (code != null) {
      dispatch({
        code,
        type: 'updateCode',
      });
    }
  });

  return (
    <RsvpFlippableCard>
      <div className="flex items-center justify-center flex-col flex-grow h-full">
        <form
          className="flex flex-col items-center justify-center md:p-8 pt-4 w-full h-full"
          onSubmit={(event) => {
            event.preventDefault();
            getInvitation(info.code);
          }}
        >
          <div className="flex flex-col items-center justify-center flex-grow w-full">
            <h1 className="mb-4">Welkom!</h1>
            <p className="max-w-sm">
              We zijn heel benieuwd om te weten of je op ons huwelijk aanwezig
              kan zijn. Geef hieronder jouw code in en dan begeleiden we je
              verder.
            </p>
            <p className="pt-4 font-schrift text-4xl">Sara & Jens</p>
          </div>
          <Field
            {...codeField('code')}
            className="w-full max-w-sm"
            errorMessages={{
              'code/notValid': 'Je moet een geldige code ingeven',
              'invitation/notFound':
                'Je code is niet gevonden in het systeem. Heb je een typfout gemaakt?',
            }}
            errors={loading ? [] : errors}
            // placeholder="SENJ"
            title={'Jouw Code'}
          />
          <Button className="w-full" type="submit">
            Start
          </Button>
        </form>
      </div>
    </RsvpFlippableCard>
  );
};
