import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  DeepPartial,
  InfoWithRequired,
  PersonInfo,
  RouteProps,
} from '../app/state';
import { selectErrors } from '../app/store';
import { PersonIndex } from '../app/validate';
import {
  booleanField,
  Button,
  ErrorCountMessage,
  Field,
  field,
  Select,
  TextArea,
} from '../lib/Form';
import { Step } from '../lib/Step';

import { Invited } from './ConfirmRoute';

const useUpdatePersonInfo = () => {
  const dispatch = useAppDispatch();
  const checkPersonInfo = useMemo(
    () =>
      debounce(() => {
        dispatch({ type: 'checkPersonInfo' });
      }, 300),
    [dispatch],
  );
  return useCallback(
    (personIndex, newPersonInfo) => {
      dispatch({
        personIndex,
        personInfo: newPersonInfo,
        type: 'updatePersonInfo',
      });
      checkPersonInfo();
    },
    [checkPersonInfo, dispatch],
  );
};

type EtenFormProps = {
  index: PersonIndex;
  info: InfoWithRequired<'invitation' | 'familyInfo'>;
};
const EtenForm: React.VFC<EtenFormProps> = ({ index, info }) => {
  const errors = useAppSelector(selectErrors);
  const updatePersonInfo = useUpdatePersonInfo();

  if (!(info.invitation.invitedToDinner || info.invitation.invitedToReceptie)) {
    return null;
  }

  const personInfo = info.personInfo?.[index];
  const etenField = field(personInfo?.eten, (newEtenInfo) => {
    updatePersonInfo(index, {
      ...personInfo,
      eten: newEtenInfo,
    });
  });

  return (
    <>
      <Select
        errorMessages={{
          [`person/${index}/eten/allergie/required`]:
            'Je moet aangeven of je voedselallergieën hebt',
        }}
        errors={errors}
        {...etenField('allergie', ...booleanField)}
        title={'Voedselallergie'}
      >
        <option value="" />
        <option value="false">ik heb geen voedselallergie</option>
        <option value="true">ik heb wel een voedselallergie</option>
      </Select>
      {info.personInfo?.[index]?.eten?.allergie && (
        <TextArea
          {...etenField('allergieOpmerking')}
          errorMessages={{
            [`person/${index}/eten/allergieOpmerking/required`]:
              'Als je allergisch bent, weten we graag waaraan',
          }}
          errors={errors}
          title={'Waaraan ben je allergisch?'}
          type=""
        />
      )}
      <Select
        errorMessages={{
          [`person/${index}/eten/vegetarisch/required`]:
            'Je moet aangeven of je vegetarisch/veganistisch gaat eten',
        }}
        errors={errors}
        {...etenField('vegetarisch')}
        title={'Eet je op onze trouw vegetarisch?'}
      >
        <option value="" />
        <option value="vegan">ik ga vegan eten</option>
        <option value="vegetarisch">ik ga alleen vegetarisch eten</option>
        <option value="pescotarier">
          ik ga vegetarisch of vis eten (pescotarisch)
        </option>
        <option value="eet-vlees">ik ga ook of alleen vlees eten</option>
      </Select>
      {info.personInfo?.[index]?.eten?.vegetarisch &&
        info.personInfo?.[index]?.eten?.vegetarisch !== 'eet-vlees' && (
          <TextArea
            {...etenField('vegetarischOpmerking')}
            errorMessages={{
              [`person/${index}/eten/vegetarischOpmerking/required`]:
                'Als je vegetariër bent, weten we graag wat je niet / wel eet',
            }}
            errors={errors}
            title={
              'Als je verder wilt uitwijden over wat je wel/niet eet, dan mag je dat hier doen'
            }
            type=""
          />
        )}
    </>
  );
};

type DansFormProps = {
  index: PersonIndex;
  info: InfoWithRequired<'invitation' | 'familyInfo'>;
};
const DansForm: React.VFC<DansFormProps> = ({ index, info }) => {
  const errors = useAppSelector(selectErrors);

  const personInfo = info.personInfo?.[index];
  const updatePersonInfo = useUpdatePersonInfo();
  const dansField = field(personInfo?.dans, (newDansInfo) => {
    updatePersonInfo(index, {
      ...personInfo,
      dans: newDansInfo,
    });
  });

  return (
    <>
      <Field
        {...dansField('verzoeknummer')}
        errorMessages={{
          [`person/${index}/dans/verzoeknummer/required`]:
            'We horen graag een verzoeknummer van jou!',
        }}
        errors={errors}
        title={'Heb je een verzoeknummer voor tijdens het dansfeest?'}
      />
    </>
  );
};

const usePersonField = (
  personInfo: DeepPartial<PersonInfo> | undefined,
  index: number,
) => {
  const updatePersonInfo = useUpdatePersonInfo();
  return field<PersonInfo>(personInfo, (newPersonInfo) => {
    updatePersonInfo(index, newPersonInfo);
  });
};

type AanwezigField = keyof PersonInfo & `aanwezig${string}`;
const Aanwezig = ({
  children,
  description,
  fieldName,
  index,
  personInfo,
  requiredMessage,
  title,
}: {
  children?: ReactNode;
  description: `${'op' | 'voor'} ${string}`;
  fieldName: AanwezigField;
  index: number;
  personInfo: DeepPartial<PersonInfo> | undefined;
  requiredMessage: string;
  title: string;
}) => {
  const errors = useAppSelector(selectErrors);
  const personField = usePersonField(personInfo, index);

  return (
    <>
      <Select
        errorMessages={{
          [`person/${index}/${fieldName}/required`]: requiredMessage,
        }}
        errors={errors}
        {...personField(fieldName, ...booleanField)}
        title={title}
      >
        <option value="" />
        <option value="true">is aanwezig {description}</option>
        <option value="false">is NIET aanwezig {description}</option>
      </Select>
      {personInfo?.[fieldName] && children}
    </>
  );
};

type PersonFormProps = {
  index: PersonIndex;
  info: InfoWithRequired<'invitation' | 'familyInfo'>;
};
const PersonForm: React.VFC<PersonFormProps> = ({ index, info }) => {
  const errors = useAppSelector(selectErrors);
  const personInfo = info.personInfo?.[index];
  const personField = usePersonField(personInfo, index);

  const disabled = index < info.invitation.fixedPersonen.length;
  const notAllFixedPersonen =
    info.invitation.fixedPersonen.length > info.familyInfo.aantalPersonen;

  const updatePersonInfo = useUpdatePersonInfo();

  return (
    <div className="">
      <div className="p-4 pb-0">
        {notAllFixedPersonen ? (
          <Select
            errorMessages={{
              [`person/${index}/voornaam/required`]:
                'Je moet een van de personen kiezen',
            }}
            errors={errors}
            title={'Naam'}
            {...personField('fixedPersonIndex')}
            onChange={(event) => {
              const fixedPersonIndex = Number(event.currentTarget.value);
              updatePersonInfo(index, {
                ...personInfo,
                familienaam:
                  info.invitation.fixedPersonen[fixedPersonIndex]?.familienaam,
                fixedPersonIndex,
                voornaam:
                  info.invitation.fixedPersonen[fixedPersonIndex]?.voornaam,
              });
            }}
          >
            <option value="-1" />
            {info.invitation.fixedPersonen.map((p, fixedPersonIndex) => {
              return (
                <option value={fixedPersonIndex}>
                  {p.voornaam} {p.familienaam}
                </option>
              );
            })}
          </Select>
        ) : (
          <>
            <Field
              {...personField('voornaam')}
              disabled={disabled}
              errorMessages={{
                [`person/${index}/voornaam/required`]: 'Voornaam is verplicht',
              }}
              errors={errors}
              title={'Voornaam'}
            />
            <Field
              {...personField('familienaam')}
              disabled={disabled}
              errorMessages={{
                [`person/${index}/familienaam/required`]:
                  'Familienaam is verplicht',
              }}
              errors={errors}
              title={'Familienaam'}
            />
          </>
        )}
        <Field
          {...personField('emailadres')}
          errorMessages={{
            [`person/${index}/emailadres/required`]: 'E-mailadres is verplicht',
          }}
          errors={errors}
          title={'E-mailadres'}
          type="email"
        />
        <Field
          {...personField('gsmNummer')}
          errorMessages={{
            [`person/${index}/gsmNummer/required`]: 'Gsm-nummer is verplicht',
          }}
          errors={errors}
          title={'Gsm-nummer'}
          type="tel"
        />
        <Invited invited={info.invitation.invitedToCeremony}>
          <Aanwezig
            description="op de huwelijksceremonie"
            fieldName={'aanwezigCeremonie'}
            index={index}
            personInfo={personInfo}
            requiredMessage="Je moet aangeven of je op de ceremonie aanwezig gaat zijn"
            title={'Huwelijksceremonie'}
          />
        </Invited>
        <Invited invited={info.invitation.invitedToReceptie}>
          <Aanwezig
            description="op de receptie"
            fieldName={'aanwezigReceptie'}
            index={index}
            personInfo={personInfo}
            requiredMessage="Je moet aangeven of je op de receptie aanwezig gaat zijn"
            title={'Receptie'}
          />
        </Invited>
        <Invited invited={info.invitation.invitedToDinner}>
          <Aanwezig
            description="voor het avondeten"
            fieldName={'aanwezigEten'}
            index={index}
            personInfo={personInfo}
            requiredMessage="Je moet aangeven of je op het avondeten aanwezig gaat zijn"
            title={'Avondeten'}
          />
        </Invited>
        {(personInfo?.aanwezigEten || personInfo?.aanwezigReceptie) && (
          <EtenForm index={index} info={info} />
        )}
        <Invited invited={info.invitation.invitedToDansfeest}>
          <Aanwezig
            description="op het dessert & dansfeest"
            fieldName={'aanwezigDans'}
            index={index}
            personInfo={personInfo}
            requiredMessage="Je moet aangeven of je op het dansfeest aanwezig gaat zijn"
            title={'Dessert & Dansfeest'}
          >
            <DansForm index={index} info={info} />
          </Aanwezig>
        </Invited>
      </div>
    </div>
  );
};

export const PersonInfoRoute = ({
  info,
  route,
}: RouteProps<'invitation' | 'familyInfo'>) => {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);

  return (
    <Step route={route} title={'Gegevens'}>
      <div className="flex flex-grow">
        <form
          className="flex flex-col md:p-8 pt-4 flex-grow"
          onSubmit={(event) => {
            event.preventDefault();
            dispatch({
              type: 'confirmPersonInfo',
            });
          }}
        >
          {Array.from({ length: info.familyInfo.aantalPersonen }).map(
            (_, index) => (
              <>
                {index > 0 && <hr />}
                <PersonForm index={index as PersonIndex} info={info} />
              </>
            ),
          )}
          <ErrorCountMessage errors={errors} />
          <Button type="submit">Volgende</Button>
        </form>
      </div>
    </Step>
  );
};
