import { RouteProps } from '../app/state';
import { Step } from '../lib/Step';

import { InfoToConfirm } from './ConfirmRoute';

const WarningForChanges = () => {
  return (
    <span className="font-bold mt-8 mb-4">
      Indien er wijzigingen dienen te gebeuren, gelieve contact op te nemen met
      Sara & Jens (
      <a
        className="text-blue-600 visited:text-purple-600 hover:underline"
        href="mailto:rsvp@sara-en-jens.be"
      >
        rsvp@sara-en-jens.be
      </a>
      )
    </span>
  );
};

export const DoneOverviewRoute = ({
  info,
  route,
}: RouteProps<'code' | 'invitation' | 'familyInfo' | 'personInfo'>) => {
  return (
    <Step backEnabled={true} route={route} title={'Overzicht antwoorden'}>
      <WarningForChanges />
      <InfoToConfirm info={info} />
      <WarningForChanges />
    </Step>
  );
};
