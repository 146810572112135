/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import {
  ButtonHTMLAttributes,
  HTMLProps,
  ReactNode,
  useEffect,
  useRef,
} from 'react';

import { DeepPartial } from '../app/state';
import { ValidationError } from '../app/validate';

export const field =
  <FormData extends unknown>(
    formData: DeepPartial<FormData> | undefined,
    onChange: (newFormData: Partial<FormData>) => void,
  ) =>
  <TFieldName extends keyof FormData>(
    fieldName: TFieldName,
    fromHtmlValueToDomain?: (value: string) => FormData[TFieldName] | undefined,
    fromDomainToHtmlValue?: (
      value: DeepPartial<FormData[TFieldName]> | undefined,
    ) => string | number | string[] | undefined,
  ) => {
    const currentValue = formData?.[fieldName];

    return {
      name: fieldName,
      onChange: (
        event: React.FormEvent<
          HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >,
      ) => {
        const { value } = event.currentTarget;

        onChange({
          ...formData,
          [fieldName]:
            fromHtmlValueToDomain != null
              ? fromHtmlValueToDomain(value)
              : value,
        });
      },
      value:
        fromDomainToHtmlValue != null
          ? fromDomainToHtmlValue(currentValue)
          : currentValue != null
          ? `${currentValue}`
          : undefined,
    };
  };
export const booleanField = [
  (value: string) => (value !== '' ? JSON.parse(value) : undefined),
  (value: boolean | undefined) => `${value}`,
] as const;

type FieldLabelProps = {
  className?: string;
  name: string | undefined;
  title: string;
};
const FieldLabel = ({ className, name, title }: FieldLabelProps) => {
  return (
    <label
      className={classNames(
        'block text-sm font-bold mr-4 py-2 font-capital',
        className,
      )}
      htmlFor={name}
    >
      {title}
    </label>
  );
};

type ErrorProps =
  | {
      errorMessages: Partial<Record<ValidationError, string>>;
      errors: ValidationError[];
    }
  | {
      errorMessages?: undefined;
      errors?: undefined;
    };
type FieldProps = HTMLProps<HTMLInputElement> &
  ErrorProps & {
    title: string;
  };
const firstMatchingErrorMessage = ({ errorMessages, errors }: ErrorProps) => {
  if (errors == null || errorMessages == null) {
    return { errorMessage: '', isError: false, isFirstError: false };
  }

  const errorMessage = errors
    .map((error) => errorMessages[error])
    .find((x) => x);
  return {
    errorMessage,
    isError: errorMessage != null && errorMessage !== '',
    isFirstError:
      errorMessage != null && errorMessage === errorMessages[errors[0]],
  };
};

const RawErrorMessage = ({
  center = false,
  children,
}: {
  center?: boolean;
  children: string | undefined | Array<string | number>;
}) => {
  return (
    <span
      className={`appearance-none w-full py-2 px-3 md:px-0 leading-tight text-red-600 text-sm ${
        center ? '' : 'md:text-left'
      }`}
    >
      {children}
    </span>
  );
};
export const ErrorMessage = (props: ErrorProps & { center?: boolean }) => {
  const { errorMessage } = firstMatchingErrorMessage(props);
  return (
    <RawErrorMessage center={props.center}>{errorMessage}</RawErrorMessage>
  );
};
export const ErrorCountMessage = (props: Pick<ErrorProps, 'errors'>) => {
  const nbErrors = props.errors?.length ?? 0;
  if (nbErrors === 0) {
    return null;
  }

  let text = `Er zijn ${nbErrors} fouten`;
  if (nbErrors === 1) {
    text = 'Er is 1 fout';
  }

  return <RawErrorMessage center={true}>{text}</RawErrorMessage>;
};

type FormElementProps = {
  children: ReactNode;
  className?: string;
  editable?: boolean;
  errorMessage: string | undefined;
  name: string | undefined;
  title: string;
};
const FormElement = ({
  children,
  className,
  editable = true,
  errorMessage,
  name,
  title,
}: FormElementProps) => {
  return (
    <div
      className={classNames([
        'flex flex-col md:flex-row items-start',
        className,
      ])}
    >
      <FieldLabel
        className={classNames('flex-initial md:text-right', {
          'md:w-1/2': !editable,
          'md:w-1/3': editable,
        })}
        name={name}
        title={title}
      />
      <div
        className={classNames(
          'flex flex-col justify-start items-start flex-initial',
          {
            'md:w-1/2': !editable,
            'md:w-2/3': editable,
            'w-full': true,
          },
        )}
      >
        {children}
        {editable && <RawErrorMessage>{errorMessage}</RawErrorMessage>}
      </div>
    </div>
  );
};

const useFocusFirstError = <
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
>(
  isFirstError: boolean,
) => {
  const inputRef = useRef<T>(null);
  useEffect(() => {
    if (isFirstError) {
      inputRef.current?.focus();
    }
  }, [inputRef, isFirstError]);

  return inputRef;
};
export const Field = (props: FieldProps) => {
  const { errorMessage, isError, isFirstError } =
    firstMatchingErrorMessage(props);
  const inputRef = useFocusFirstError<HTMLInputElement>(isFirstError);

  return (
    <FormElement
      className={props.className}
      errorMessage={errorMessage}
      name={props.name}
      title={props.title}
    >
      <input
        {...props}
        className={classNames(
          'appearance-none w-full py-2 px-3 leading-tight focus:shadow',
          {
            'border-red-600': isError,
          },
          !props.disabled && ['border-b'],
        )}
        ref={inputRef}
      />
    </FormElement>
  );
};

type TextAreaProps = HTMLProps<HTMLTextAreaElement> &
  ErrorProps & {
    title: string;
  };
export const TextArea = (props: TextAreaProps) => {
  const { errorMessage, isError, isFirstError } =
    firstMatchingErrorMessage(props);
  const inputRef = useFocusFirstError<HTMLTextAreaElement>(isFirstError);

  return (
    <div className="mb-4">
      <FieldLabel name={props.name} title={props.title} />
      <textarea
        className={classNames(
          'appearance-none w-full py-2 px-3 leading-tight focus:shadow border',
          {
            'border-red-600': isError,
          },
        )}
        ref={inputRef}
        rows={5}
        {...props}
      />
      <RawErrorMessage>{errorMessage}</RawErrorMessage>
    </div>
  );
};

type SelectProps = HTMLProps<HTMLSelectElement> &
  ErrorProps & {
    title: string;
  };
export const Select = (props: SelectProps) => {
  const { errorMessage, isError, isFirstError } =
    firstMatchingErrorMessage(props);
  const inputRef = useFocusFirstError<HTMLSelectElement>(isFirstError);

  return (
    <FormElement
      errorMessage={errorMessage}
      name={props.name}
      title={props.title}
    >
      <select
        className={classNames(
          'w-full py-2 px-3 leading-tight focus:shadow-outline bg-white border-b',
          {
            'border-red-600': isError,
          },
        )}
        ref={inputRef}
        {...props}
      />
    </FormElement>
  );
};

export const Button = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...props}
      className={classNames([
        'border border-pink font-capital hover:bg-pink font-bold py-2 md:px-4 mt-auto',
        props.className,
      ])}
    />
  );
};

type ConfirmFieldProps = {
  children: ReactNode;
  title: string;
};
export const ConfirmField = ({ children, title }: ConfirmFieldProps) => {
  return (
    <FormElement
      editable={false}
      errorMessage={undefined}
      name={undefined}
      title={title}
    >
      <span
        className={classNames(
          'appearance-none leading-normal',
          'text-left py-2 px-3',
        )}
      >
        {children}
      </span>
    </FormElement>
  );
};
